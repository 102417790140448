import { template as template_09e68cb1db244525b04a9778f75ab0b7 } from "@ember/template-compiler";
const FKText = template_09e68cb1db244525b04a9778f75ab0b7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
