import { template as template_87bad74da12748e3beffc5a6a3553b1f } from "@ember/template-compiler";
const FKLabel = template_87bad74da12748e3beffc5a6a3553b1f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
